
.commentModal > .modal-content > .modal-dialog{
    margin-top: 0;
    margin-bottom: 0;
}

.closeIssueModal > .modal-content > .modal-dialog{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}
.requiresRemediationModal > .modal-content > .modal-dialog{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

.panel.panel-danger.errors{
    display: none;
}