hr.border-0.bg-secondary{
    height: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.row > div.col > div.form-group > div.container-fluid > div.row > div.col{
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.my-1{
    display: none;
}

div#visnField{
    display: block;
}
/* 
div#visnField > div.col > div.form-group > div.container-fluid{
    display: flex;
    flex-direction: column-reverse;
}

div#visnField > div.col > div.form-group > div.container-fluid > div.row > div.col > div.form-group > div.container-fluid{
    display: flex;
    flex-direction: column-reverse;
} */
