/*header*/
.mstr-header-logo-row {
    /*background-image: url('/centrallogin/resources/images/cl-dark-blue.png')*/
    background-color: #05263F;
}

.mstr-legal-warning-row {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: 5vh;
    margin-right: 5vh;
}

.mstr-warning {
    color: #990000;
    text-align: right;
}

.mstr-warning-body {
    font-size: 11px;
}

.mstr-footer-div {
    margin: -20px;
    background: #05263F;
    height: 400px;
    color: #010c29;
}

.template-footer-row2 {
    margin-top: 5px;
}

ul.template-link-list {
    text-align: center;
    padding: 0;
    width: 100%;
    border-bottom: none !important;
    display: block;
    margin: 5px 0 0 0;
}

.template-footer-row1 ul.template-link-list a {
    display: inline-block;
    padding: 0;
}

.template-footer-row1 ul.template-link-list li {
    padding: 0;
    margin: 0;
    display: inline-block;
    text-align: center;
}

.template-footer-row1 ul.template-link-list:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.template-footer-row1 ul.template-link-list li:after {
    margin: 0 20px 0 20px;
    content: "|";
    padding: 0 .15em;
}

.template-footer-row1 ul.template-link-list li:last-of-type::after {
    margin: 0;
    padding: 0;
    content: "";
}

.template-footer-align {
    text-align: center;
}

.style-guide-footer-hyperlink {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #BDD5EC;
}

.style-guide-body-text-smallest-white {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 10px !important;
    font-weight: normal !important;
    color: white !important;
}

.va-nav-header-row {
    color: white;
    margin-left: 15px;
}

/* menue breadcrumb */
.i5breadcrumb {
    margin-bottom: 0px !important;
}

.breadcrumb {
    background: linear-gradient(to left, #1677BD, #00467F);
    margin: -2px -15px 0px -16px;
}
    .breadcrumb a {
        color: white;
    }