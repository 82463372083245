
#content {
    width: calc(100% - 250px);
    padding: 10px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

#content.active {
    width: 100%;
}

button#actionButton{
    white-space: normal;
}

.dropdown-toggle::after{
    right: 0.5em !important
}
ul.pagination.react-bootstrap-table-page-btns-ul{
      float: right;
  }